import { required, email as validEmail, minLength } from '@vuelidate/validators';
const validDomains = process.env.VUE_APP_VALID_DOMAINS;

const password = {
  required,
  minLength: minLength(8),
  valid: (value) => {
    const containsUppercase = /[A-Z]/.test(value);
    const containsLowercase = /[a-z]/.test(value);
    const containsNumber = /[0-9]/.test(value);

    return containsUppercase && containsLowercase && containsNumber;
  }
};

const email = {
  required,
  validEmail,
  valid: (value) => {
    if (!validDomains) return true;
    const domain = value.split('@')[1];

    return validDomains.includes(domain);
  }
};

export {
  password,
  email
};
