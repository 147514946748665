import { Auth } from 'aws-amplify';
import { empty } from '@/utils/object';

const authenticated = async () => {
  return !empty(await Auth.currentUserInfo());
};

export {
  authenticated
};
