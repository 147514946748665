import request from './request';

const url = `${process.env.VUE_APP_INSTANCES_API_URL}/tenants`;

const create = function (data) {
  return request({
    url,
    method: 'POST',
    data: data
  });
};

const destroy = function (id) {
  return request({
    url: `${url}/${id}`,
    method: 'DELETE'
  });
};

const list = function () {
  return request({
    url,
    method: 'GET'
  });
};

const deleteAll = function () {
  return request({
    url,
    method: 'DELETE'
  });
};

const Instances = {
  create,
  destroy,
  list,
  deleteAll
};

export default Instances;
