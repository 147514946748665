<template>
  <v-text-field
    v-bind="$attrs"
    :id="password"
    v-model="password"
    :type="showPassword ? 'text' : 'password'"
    :autocomplete="autocomplete"
    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
    :placeholder="placeholder"
    required
    :label="label"
    outlined
    :hint="tooltip"
    :error="error"
    :error-messages="errorMessages"
    @input="$emit('input', $event)"
    @blur="$emit('blur')"
    @click:append="showPassword = !showPassword"
  />
</template>

<script>
export default {
  name: 'Password',
  props: {
    id: {
      type: String,
      default: 'input-password'
    },
    label: {
      type: String,
      default: 'Password',
    },
    placeholder: {
      type: String,
      default: '',
    },
    autocomplete: {
      default: 'current-password',
      type: String
    },
    tooltip: {
      default: 'At least one uppercase letter, one lowercase letter, numbers and 8 characters',
      type: String
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: [String, Array],
      default: ''
    }
  },
  data() {
    return {
      password: '',
      showPassword: false,
    };
  }
};
</script>
