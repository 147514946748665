<template>
  <v-row no-gutters>
    <v-col
      class="public-intro d-none d-md-block"
    />
    <v-col
      class="right-side"
      md="6"
    >
      <v-row class="logo">
        <v-col cols="12">
          <v-row no-gutters>
            <router-link :to="{ name: 'Login' }">
              <logo
                type="horizontal"
                :size="100"
              />
            </router-link>
            <v-spacer />
            <theme-switch />
          </v-row>
        </v-col>
      </v-row>

      <v-scroll-x-transition :hide-on-leave="true">
        <router-view />
      </v-scroll-x-transition>
    </v-col>
  </v-row>
</template>

<script>
import Logo from '@/components/shared/Logo';
import ThemeSwitch from '@/components/shared/ThemeSwitch';

export default {
  components: {
    Logo,
    ThemeSwitch,
  },
  data () {
    return {
      notification: this.$route.params.notification
    };
  },
  created () {
    if (this.notification) {
      this.$notify(this.notification);
    }
  }
};
</script>

<style lang="scss" scoped>
.public-intro {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("~@/assets/intro/pattern.jpg");
}
.right-side {
  padding: 2em !important;
}
.logo {
  padding-bottom: 2em;
}
</style>
