<template>
  <div class="check-email">
    <div class="mt-3 mb-3 p-3 text-center">
      <h1>
        Well done!
      </h1>
    </div>
    <div class="envelope">
      <div class="paper" />
    </div>
    <div class="my-5 text-center">
      <h3 class="text-white p-3">
        We sent you the verification email.<br>
        Please, check your inbox and verify your email address.
      </h3>
    </div>
    <div class="mt-3 mb-3 p-3">
      <md-field
        v-if="!signup.email"
      >
        <label>E-Mail Address</label>
        <md-input
          id="email"
          v-model="email"
          type="text"
          autocomplete="email"
          required
        />
      </md-field>
    </div>
    <div class="my-5">
      <v-btn
        color="primary"
        block
        x-large
        @click="resend"
      >
        Resend Confirmation
      </v-btn>
    </div>
    <div>
      <v-btn
        :to="{ name: 'Login' }"
        block
        x-large
      >
        To Login
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Auth } from 'aws-amplify';

export default {
  data() {
    return {
      email: ''
    };
  },
  computed: {
    ...mapGetters('auth', [
      'signup'
    ]),
    username() {
      return this.email.split('@')[0];
    }
  },
  methods: {
    async resend() {
      try {
        await Auth.resendSignUp(this.signup.username);
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: error.message
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .md-button {
    width: 100%;
    min-height: 4em;
    margin: 2em 0;
  }
  .md-field {
    margin-top: 40px;
  }
  .txt-success {
    color: #316FB5;
  }
  .success {
    background: #316FB5;
  }
</style>
