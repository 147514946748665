<template>
  <div class="loading">
    <logo
      :size="150"
      style="padding-bottom: 2em;"
    />
    <v-progress-linear
      indeterminate
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Logo from '@/components/shared/Logo';

export default {
  components: {
    Logo
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ])
  },
  async mounted() {
    try {
      await this.$store.dispatch('fetchData', this.user);
      console.info(`🚀🚀🚀🚀🚀🚀`);
      await this.$router.push({ name: 'Dashboard' });
      this.$notify({
        text: 'Successfully signed in'
      });
    } catch (error) {
      this.$notify({
        type: 'error',
        title: 'Error',
        text: `Coulnd't connect to the server: ${error.message}`
      });
      await this.$store.dispatch('logout');
    } finally {
      await this.$store.dispatch('application/loading', false);
    }
  }
};
</script>

<style lang="scss" scoped>
.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
