<template>
  <div>
    <h1 class="mb-5">
      Confirm registration
    </h1>

    <form
      v-if="showForm"
      @submit.prevent="confirm"
    >
      <v-text-field
        id="email"
        v-model="email"
        type="text"
        autocapitalize="none"
        inputmode="email"
        autocomplete="email"
        required
        label="E-Mail Address"
        outlined
        hint="use your email address"
      />

      <v-text-field
        id="code"
        v-model="code"
        type="text"
        required
        label="Confirmation Code"
        outlined
      />

      <v-btn
        block
        x-large
        color="primary"
        type="submit"
        :disabled="v$.invalid"
      >
        Confirm
      </v-btn>
    </form>

    <p class="my-5">
      Didn't receive the code?

      <v-btn
        block
        x-large
        @click="resend"
      >
        Resend confirmation
      </v-btn>
    </p>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { mapGetters } from 'vuex';
import {
  required,
  minLength,
  email
} from '@vuelidate/validators';

export default {
  name: 'ConfirmRegistration',
  setup () {
    return {
      v$: useVuelidate()
    };
  },
  data () {
    return {
      error: null,
      username: this.$route.query.username,
      code: this.$route.query.code || '',
      email: this.$store.state.auth.signup.email || '',
    };
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
      code: {
        required,
        minLength: minLength(6)
      }
    };
  },
  computed: {
    ...mapGetters('auth',[
      'signup'
    ]),
    showForm() {
      // return true
      return (this.code.length !== 6 && !(this.username || this.email));
    },
  },
  created: function () {
    // deletes query params from the URL
    this.$router.push(this.$route.path);

    if (this.username && this.code) {
      this.confirm();
    }
  },
  methods: {
    async confirm() {
      try {
        await this.$store.dispatch('auth/confirm', this.code);
        this.$router.replace({
          name: 'Login',
          params: {
            email: this.email,
            success: 'Successfully confirmed. Sign in with your password.'
          }
        });
      } catch (error) {
        this.$notify({
          type: 'error',
          text: error
        });
      }
    },
    async resend() {
      // const username = this.email.split('@')[0]
      try {
        await this.$store.dispatch('auth/resendCode');
        this.$notify({
          type: 'info',
          text: 'New code sucessfully sent to your email address'
        });
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: error.message
        });
      }
    }
  }
};
</script>
