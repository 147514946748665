import Vue from 'vue';

//const mobile = () => {
//  const mobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
//  const mobileBrowser = (window.innerWidth <= 600)
//  const isMobile = mobileDevice || mobileBrowser
//  return isMobile
//}

const mobile = () => {
  const mobileWidth = 600;

  return window.innerWidth <= mobileWidth;
};

const prefersDarkMode = () => {
  return window.matchMedia
         && window.matchMedia('(prefers-color-scheme: dark)').matches;
};

Vue.prototype.$config = {
  mobile: mobile(),
  prefersDarkMode: prefersDarkMode()
};
