<template>
  <img
    :src="logo"
    class="align-middle"
    :width="size"
  >
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Logo',
  props: {
    size: {
      type: Number,
      default: 93
    }
  },
  computed: {
    ...mapGetters('application', [
      'theme'
    ]),
    logo() {
      return require(`../../assets/logos/logo-${this.theme}.png`);
    }
  }
};
</script>
