import request from './request';

const url = `${process.env.VUE_APP_API_URL}/apps`;

const create = function (instanceId, data) {
  return request({
    url,
    method: 'POST',
    data,
    headers: { InstanceId: instanceId }
  });
};

const list = function (instanceId) {
  return request({
    url,
    method: 'GET',
    headers: { InstanceId: instanceId }
  });
};

const get = function (instanceId, id) {
  return request({
    url: `${url}/${id}`,
    method: 'GET',
    headers: { InstanceId: instanceId }
  });
};

const update = function (instanceId, id, data) {
  return request({
    url: `${url}/${id}`,
    method: 'PUT',
    data,
    headers: { InstanceId: instanceId }
  });
};

const destroy = function (instanceId, id) {
  return request({
    url: `${url}/${id}`,
    method: 'DELETE',
    headers: { InstanceId: instanceId }
  });
};

const Apps = {
  create,
  list,
  get,
  update,
  destroy
};

export default Apps;
