import request from './request';
const usersUrl = `${process.env.VUE_APP_API_URL}/users`;

const destroy = function () {
  return request({
    url: usersUrl,
    method: 'DELETE'
  });
};

const get = function () {
  return request({
    url: usersUrl,
    method: 'GET'
  });
};

const update = function (data) {
  return request({
    url: usersUrl,
    method: 'PUT',
    data
  });
};

const Users = {
  get,
  update,
  destroy
};

export default Users;
