<template>
  <div>
    <app-bar />

    <side-menu
      v-if="$vuetify.breakpoint.smAndUp"
    />

    <v-main>
      <v-container>
        <v-scroll-x-transition :hide-on-leave="true">
          <router-view />
        </v-scroll-x-transition>
      </v-container>
    </v-main>

    <bottom-menu
      v-if="$vuetify.breakpoint.mdAndDown"
    />
  </div>
</template>

<script>
import AppBar from '@/layouts/Private/AppBar';
import SideMenu from '@/layouts/Private/SideMenu';
import BottomMenu from '@/layouts/Private/BottomMenu';

export default {
  name: 'PrivateLayout',
  components: {
    AppBar,
    SideMenu,
    BottomMenu,
  },
};
</script>
