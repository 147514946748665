import request from './request';
const rootUrl = process.env.VUE_APP_INSTANCES_API_URL;

const timezones = function () {
  return request({
    url: `${rootUrl}/i18n/timezones`,
    method: 'GET'
  });
};

const fetchTimezones = async () => {
  try {
    const response = await timezones();

    return response.map((element) => {
      const comments = element.comments ? `(${element.comments})` : '';
      const timezone = {
        value: element.name,
        text: `${element.name} ${comments}`
      };

      return timezone;
    })
      .sort((a, b) => {
        return a.text.localeCompare(b.text);
      });
  } catch (error) {
    throw new Error(`Couldn't fetch the timzones: ${error}`);
  }
};

const Settings = {
  timezones,
  fetchTimezones
};

export default Settings;
