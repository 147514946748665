<template>
  <div>
    <h1 v-if="openedFromTheLink">
      Confirm password reset
    </h1>
    <h1 v-else>
      Check your email for the password reset link
    </h1>

    <form
      v-show="openedFromTheLink"
      @submit.prevent="passwordResetConfirm"
    >
      <password
        v-model="password"
        label="New Password"
        placeholder="Enter your new password"
      />
      <v-btn
        block
        x-large
        color="primary"
        type="submit"
        :loading="loading"
        :disabled="v$.$invalid"
      >
        Save
      </v-btn>
    </form>

    <div class="resend-confirmation">
      <h3
        class="centered"
      >
        Didn't receive the email?
      </h3>
      <div class="my-5">
        <v-btn
          id="resendCode"
          color="primary"
          :loading="loading"
          block
          x-large
          @click="resendCode"
        >
          Resend Confirmation
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { password } from '@/utils/validators';
import { mapActions, mapGetters } from 'vuex';
import Password from '@/components/shared/Forms/Password';

export default {
  components: {
    Password
  },
  setup () {
    return {
      v$: useVuelidate()
    };
  },
  data () {
    return {
      code: this.$route.query.code,
      username: this.$route.query.username,
      password: '',
      error: null,
      loading: false
    };
  },
  validations: {
    password,
  },
  computed: {
    ...mapGetters('auth', {
      user: 'forgotPassword'
    }),
    openedFromTheLink () {
      return !!(this.username && this.code);
    }
  },
  created() {
    this.$store.commit('auth/forgotPassword', {
      code: this.code,
      username: this.username
    });
    // deletes query params from the URL
    this.$router.push(this.$route.path);
  },
  methods: {
    ...mapActions('auth', [
      'forgotPassword',
      'forgotPasswordSubmit'
    ]),
    async passwordResetConfirm() {
      this.loading = true;

      try {
        await this.forgotPasswordSubmit(this.password);
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: error
        });
      } finally {
        this.loading = false;
      }
    },
    async resendCode() {
      this.loading = true;
      const { email } = this.user;

      try {
        await this.forgotPassword(email);
        this.$notify({
          text: 'Successfully resent reset link'
        });
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: error
        });
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.resend-confirmation {
  padding-top: 2em;
}
</style>
