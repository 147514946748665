import colors from 'vuetify/es5/util/colors.js';

export default {
  light: {
    primary: colors.lightBlue.darken1,
    secondary: colors.teal.base,
    tertiary: colors.red.lighten5,
    quaternary: colors.grey.lighten4,
    quinary: colors.grey.lighten2,
    accent: colors.pink.base,
    error: colors.pink.darken3,
    background: '#fafafa',
    surface: '#ffffff',
    surface2: '#f2f2f2'
  },
  dark: {
    primary: colors.blue.darken1,
    secondary: colors.teal.base,
    tertiary: colors.red.lighten5,
    quaternary: '#50535a',
    quinary: colors.grey.darken1,
    accent: colors.pink.base,
    error: colors.pink.lighten1,
    background: '#404248',
    surface: '#545760',
    surface2: '#4b4e57'
  },
};
