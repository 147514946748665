<template>
  <div>
    <h1 class="mb-5">
      Register
    </h1>

    <form-registration />

    <div class="mt-5 text-uppercase text-center">
      <router-link
        id="login"
        :to="{ name: 'Login' }"
      >
        Login
      </router-link>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    FormRegistration: () => import('../../components/Registration/FormRegistration.vue')
  },
});
</script>
