import { CognitoIdentityProvider } from '@aws-sdk/client-cognito-identity-provider';
import { Auth } from 'aws-amplify';
import services from '@/services';
import router from '@/router';
import { authenticated } from '@/utils/auth';

// this piece of code is needed
// only because aws-amplify doesn't offer
// a way to delete a Cognito user yet
// We call currentAuthenticatedUser to get a current AccessToken

const deleteCognitoUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true
    });
    // VUE_APP_COGNITO_REGION=eu-west-1
    const { pool: { userPoolId } } = user;
    const region = userPoolId.split('_')[0];
    const cognitoIdentityProvider = new CognitoIdentityProvider({ region });
    const params = {
      AccessToken: user.signInUserSession.accessToken.jwtToken
    };

    cognitoIdentityProvider.deleteUser(params, function(err, data) {
      if (err) { console.error(err); }
      console.warn(data);
    });
  } catch (error) {
    throw new Error(error);
  }
};

function defaultState() {
  return {
    user: {},
    signup: {},
    forgotPassword: {
      email: null,
      code: null,
      username: null,
    },
    authenticated: false,
  };
}

const state = defaultState();

const getters = {
  user: (state) => state.user,
  signup: (state) => state.signup,
  forgotPassword: (state) => state.forgotPassword,
  authenticated: (state) => state.authenticated,
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  setAuth(state, payload) {
    state.authenticated = payload;
  },
  signout(state) {
    state.authenticated = false;
  },

  confirmed(state) {
    state.signup = { ...state.signup, ...{ password: null }};
  },
  updateUser (state, payload) {
    state.user = {
      ...state.user,
      ...payload
    };
  },
  signup(state, payload) {
    state.signup = payload;
  },
  forgotPassword(state, payload) {
    state.forgotPassword = {
      ...state.forgotPassword,
      ...payload
    };
  }
};

const actions = {
  reset({ commit }) {
    commit('reset');
    commit('resetState', null, { root: true });
  },
  login({ commit }, payload) {
    commit('updateUser', payload);
    commit('setAuth', true);
  },
  async signin({ dispatch }, auth) {
    try {
      const { username, password } = auth;

      await Auth.signIn(username, password);//check login success
      await Auth.signOut({ global: true });// sign out all users on any devices by current.
      const { attributes: user } = await Auth.signIn(username, password);// sign in again.

      dispatch('login', user);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async signout({ commit }) {
    try {
      if (await authenticated()) {
        await Auth.signOut();
      }
      commit('signout');

    } catch (error) {
      throw new Error(error.message);
    }
  },
  async signup({ commit }, registration) {
    // tos should be stored as the date of acceptance
    try {
      await Auth.signUp({
        username: registration.username,
        password: registration.password,
        attributes: {
          email: registration.email,
          'custom:marketing': registration.marketing
        },
      });
      commit('signup', registration);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async confirm({ getters, commit }, code) {
    const { username } = getters['signup'];

    try {
      await Auth.confirmSignUp(username, code);
      commit('confirmed');
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async resendCode({ getters }) {
    const { email } = getters['signup'];
    const username = email.split('@')[0];

    try {
      await Auth.resendSignUp(username);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async forgotPassword({ commit }, email) {
    try {
      const result = await Auth.forgotPassword(email);
      console.error(`Forgot password result: ${JSON.stringify(result)}`);
      commit('forgotPassword', { email });
      router.push({
        name: 'PasswordResetConfirm',
        params: {
          email,
          success: 'Please, check your email for the password reset link'
        }
      });
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async forgotPasswordSubmit({ state }, password) {
    const { email, code, username } = state.forgotPassword;

    try {
      await Auth.forgotPasswordSubmit(email || username, code, password);
      router.push({
        name: 'Login',
        params: {
          email,
          success: 'Password changed successfully'
        }
      });
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async updateUser({ commit }, userAttributes) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, userAttributes);
      commit('updateUser', userAttributes);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async deleteUser({ commit, dispatch }) {
    dispatch('application/loading', true, { root: true });

    try {
      await services.instances.deleteAll(this.email);
      await services.users.destroy();
      commit('reset');
      commit('resetState', null, { root: true });

      await deleteCognitoUser();
      await dispatch('signout');
    } catch (error) {
      throw new Error(error);
    } finally {
      dispatch('application/loading', false, { root: true });
    }
  },
  nextStep({ commit }) {
    commit('nextStep');
  },
  previousStep({ commit }) {
    commit('previousStep');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
