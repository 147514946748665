<template>
  <div>
    <h1 class="mb-5">
      Sign In
    </h1>

    <form-login />

    <!-- Forgot Password -->
    <div class="mt-5 text-uppercase text-center">
      <router-link
        id="password-reset"
        :to="{ name: 'PasswordReset' }"
      >
        Forgot Password
      </router-link>
    </div>
    <br>
    <v-btn
      id="register"
      color="secondary"
      block
      x-large
      :to="{ name: 'Register' }"
    >
      Create Account
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'SigninComponent',
  components: {
    FormLogin: () => import('../components/Login/FormLogin.vue')
  },
  data() {
    return {
      notification: this.$route.params.notification,
      success: this.$route.params.success,
      auth: {
        email: this.$store.state.auth.signup.email || '',
        password: ''
      },
      loading: false,
      validDomains: process.env.VUE_APP_VALID_DOMAINS
    };
  },
  created() {
    if (this.notification) {
      this.$notify(this.notification);
    }

    if (this.success) {
      this.$notify({
        type: 'success',
        text: this.success
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.intro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
