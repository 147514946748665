export default [
  {
    label: 'Dashboard',
    route: '/dashboard',
    icon: 'dashboard'
  },
  {
    label: 'Releases',
    route: '/releases',
    icon: 'new_releases'
  },
  {
    label: 'Settings',
    route: '/settings',
    icon: 'settings'
  }
];
