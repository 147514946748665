<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="headline red">
          Debugger
        </v-card-title>

        <v-card-text>
          Version: {{ version }}
        </v-card-text>
        <v-divider />

        <v-card-text>
          SHA: {{ sha }}
        </v-card-text>
        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
export default {
  data() {
    return {
      dialog: false,
      sha: process.env.VUE_APP_SHA,
      version: process.env.VUE_APP_VERSION
    };
  },
  methods: {
    toggle() {
      this.dialog = !this.dialog;
    }
  }
};
</script>
