import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import PublicRoutes from '@/router/public';
import PrivateRoutes from '@/router/private';
import {authenticated} from '@/utils/auth';

const router = new Router({
  linkActiveClass: 'active-route',
  mode: 'history',
  fallback: true,
  base: __dirname,
  routes: [
    {
      path: '*',
      redirect: '/dashboard',
      meta: {
        public: false,
      },
    },
  ].concat(PublicRoutes)
    .concat(PrivateRoutes)
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch('application/setPageTitle', to.name);
  const isAuthenticated = store.state.auth.authenticated;
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyLoggedOut = to.matched.some((record) => record.meta.onlyLoggedOut);

  // if you want to reach the auth area but you're not authenticated
  if (!isPublic && !isAuthenticated) {
    return next({
      name: 'Login',
      query: { redirect: to.fullPath },
    });
  }

  if (to.name === 'Login') {
    await store.dispatch('auth/signout');

    return next();
  }

  if(!isPublic){
    if(!await authenticated()){
      window.location.href = '/';
    }
  }

  if (isAuthenticated && onlyLoggedOut) {
    return next('/');
  }
  next();
});

Vue.use(Router);

export default router;
