<template>
  <v-bottom-navigation
    app
  >
    <v-btn
      v-for="(item, index) in menu"
      :key="index"
      :to="item.route"
      exact
    >
      <span>{{ item.label }}</span>
      <v-icon>{{ item.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import menu from '@/config/menu.js';

export default {
  name: 'BottomMenu',
  data () {
    return {
      menu
    };
  }
};
</script>
