import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persistedstate';
import offlineState from './offline';
import router from '@/router';
import Instances from '@/services/instances';
import Settings from '@/services/settings';
import auth from './modules/auth';
import application from './modules/application';
import apps from './modules/apps';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'mbanq'
});

const defaultState = () => {
  return {
    user: {},
    instances: [],
    timezones: [],
    applications: [],
    activeInstance: ''
  };
};

const state = process.env.NODE_ENV === 'offline'
  ? offlineState
  : defaultState;

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: state,
  modules: {
    auth,
    application,
    apps
  },
  getters: {
    user: state => state.user,
    instances: state => state.instances,
    instanceIdentifiers: state => state.instances.map(({ identifier }) => { return identifier; }),
    getInstance: state => identifier => state.instances.find(instance => instance.identifier === identifier),
    timezones: state => state.timezones,
    applications: state => state.applications,
    activeInstance: state => state.activeInstance,
  },
  mutations: {
    resetState (state) {
      Object.assign(state, defaultState());
    },
    authenticate (state) {
      state.authenticated = true;
    },
    updateUser (state, payload) {
      Object.assign(state.user, payload);
    },
    addInstance (state, payload) {
      state.instances.push(payload);
      state.activeInstance = payload;
    },
    removeInstance (state, instance) {
      state.instances.remove(instance);
      state.activeInstance = null;
    },
    instances (state, payload) {
      state.instances = payload;
    },
    timezones (state, payload) {
      state.timezones = payload;
    },
    applications (state, payload) {
      state.applications = payload;
    },
    activeInstance (state, payload) {
      state.activeInstance = payload;
    },
  },
  actions: {
    resetState ({ commit }) {
      commit('resetState');
    },
    logout ({ commit }) {
      commit('resetState');
      commit('auth/setAuth', false);
      router.push({ name: 'Login' });
    },
    updateUser ({ commit }, payload) {
      commit('updateUser', payload);
    },
    async fetchData ({ commit}, user) {
      try {
        const instances = await Instances.list(user.email);
        const timezones = await Settings.fetchTimezones();
        commit('instances', instances);
        commit('timezones', timezones);
        commit('auth/setAuth', true);
      } catch (error) {
        //await dispatch('logout')
        throw new Error(`Couldn't fetch the data: ${error.message}`);
      }
    },
    async fetchInstances ({ commit }, owner) {
      try {
        const instances = await Instances.list(owner);
        commit('instances', instances);
      } catch (error) {
        throw new Error(`Couldn't fetch the instances: ${error.message}`);
      }
    },
    async fetchTimezones ({ commit }) {
      try {
        const response = await Settings.timezones();
        const timezones = response.map((element) => {
          const comments = element.comments ? `(${element.comments})` : '';
          const timezone = {
            value: element.name,
            text: `${element.name} ${comments}`
          };

          return timezone;
        })
          .sort((a, b) => {
            return a.text.localeCompare(b.text);
          });
        commit('timezones', timezones);
      } catch (error) {
        throw new Error(`Couldn't fetch the instance settings: ${error.message}`);
      }
    },
    async addInstance ({ commit }, payload) {
      try {
        const instance = await Instances.create(payload);
        commit('addInstance', instance);
      } catch (error) {
        throw new Error(`Couldn't add the instance: ${error.response.data.message}`);
      }
    },
    async removeInstance ({ commit }, instance) {
      try {
        await Instances.destroy(instance.identifier);
        commit('removeInstance', instance);
      } catch (error) {
        throw new Error(`Couldn't delete the instance: ${error.message}`);
      }
    },
    instances ({ commit }, payload) {
      commit('instances', payload);
    },
    applications ({ commit }, payload) {
      commit('applications', payload);
    },
    activeInstance ({ commit }, payload) {
      commit('activeInstance', payload);
    },
  },
  plugins: [vuexLocalStorage]
});

export default store;
