<template>
  <div :class="{ blurred: active }">
    <div
      class="spinner"
      :class="{ active }"
    />
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  data () {
    return {
      active: false
    };
  },
  created () {
    // TODO: find out why following doesn't work
    // this.$bus.$emit('blur', true/false)
    // this.$bus.$on('blur', function (state) { this.blurred = state })
    this.$bus.$on('blur:on', this.blurOn);
    this.$bus.$on('blur:off', this.blurOff);
  },
  methods: {
    blurOn () {
      this.active = true;
    },
    blurOff () {
      this.active = false;
    }
  }
};

</script>

<style lang="scss">
.fa-info-circle {
  color: #878e95;
}
.bb {
  border-bottom-width: 1px;
  border-bottom-color: rgb(225, 228, 232);
  border-bottom-style: solid;
  margin-bottom: 10px;
}

.blurred {
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);

  .spinner {
    display:none;
    z-index: 99999;
    position: absolute;
    left:-webkit-calc(50% - 60px);
    left:-moz-calc(50% - 60px);
    left:calc(50% - 60px);
    top:-webkit-calc(50% - 60px);
    top:-moz-calc(50% - 60px);
    top:calc(50% - 60px);
    border: 16px solid #f3f3f3;
    border-top: 16px solid #007bff;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    &.active {
      display:block;
    }
  }

  @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }
}
</style>
