<template>
  <div>
    <v-btn
      icon
      @click="switchTheme"
    >
      <v-icon>
        {{ icon }}
      </v-icon>
    </v-btn>
    <span v-if="label">{{ label }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ThemeSwitch',
  props: {
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('application', [
      'theme',
    ]),
    icon() {
      const icons = {
        dark: 'mdi-weather-sunny',
        light: 'mdi-weather-night',
      };

      return icons[`${this.theme}`];
    },
  },
  methods: {
    switchTheme() {
      this.$store.dispatch('application/switchTheme');
      this.$vuetify.theme.dark = this.theme === 'dark';
    },
  },
};
</script>
