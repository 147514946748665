// private area
import store from '@/store';
import lazyLoad from '@/utils/lazyLoad';

const routes = [
  {
    component: lazyLoad('views/Dashboard'),
    path: '/dashboard',
    name: 'Dashboard',
    props: true
  },
  {
    component: lazyLoad('views/Releases'),
    path: '/releases',
    name: 'Releases'
  },
  {
    component: lazyLoad('views/Instances/New'),
    path: '/instances/create',
    name: 'New Instance'
  },
  {
    component: lazyLoad('views/Instances/Show'),
    path: '/instances/:identifier',
    name: 'Instance',
    props: true,
    beforeEnter(to, _, next) {
      const ids = store.getters.instanceIdentifiers;
      const identifier = to.params.identifier;

      if (ids.includes(identifier)) {
        return next();
      }
      next({
        name: 'Dashboard',
        params: {
          instanceExists: false
        }
      });
    }
  },
  {
    component: lazyLoad('views/Apps/Index'),
    path: '/instances/:identifier/apps',
    props: true,
    name: 'Apps'
  },
  {
    component: lazyLoad('views/Apps/Show'),
    path: '/instances/:identifier/apps/:id',
    props: true,
    name: 'App'
  },
  {
    component: lazyLoad('views/Apps/Edit'),
    path: '/instances/:identifier/apps/:id/edit',
    props: true,
    name: 'Edit App'
  },
  {
    component: lazyLoad('views/Apps/New'),
    path: '/apps/create',
    props: true,
    name: 'New App'
  },
  {
    path: '/settings/account'
  },
  {
    component: lazyLoad('views/Settings/Index'),
    path: '/settings',
    name: 'Settings'
  },
  {
    path: '/logout',
    async beforeEnter (to, from, next) {
      await store.dispatch('auth/signout');
      next('/');
    },
    name: 'Logout'
  }
];

export default routes;
