<template>
  <v-overlay
    :absolute="true"
    :opacity="0.8"
    :value="!onLine"
    :z-index="0"
  >
    <div
      class="glitch"
      data-text="Offline"
    >
      <span class="glitch__color glitch__color--red">Offline</span>
      <span class="glitch__color glitch__color--blue">Offline</span>
      <span class="glitch__color glitch__color--green">Offline</span>
      <span class="glitch__main">Offline</span>
      <span class="glitch__line glitch__line--first" />
      <span class="glitch__line glitch__line--second" />
    </div>
  </v-overlay>
</template>
<script>
export default {
  data() {
    return {
      onLine: navigator.onLine,
    };
  },
  watch: {
    onLine(v) {
      if (v) {
        this.$notify({
          type: 'info',
          title: 'Info',
          text: 'You are back online!'
        });

        return;
      }
      this.$notify({
        type: 'error',
        title: 'Error',
        text: 'You appear to be offline'
      });
    },
  },
  mounted() {
    window.addEventListener('online', this.handleNetworkChange);
    window.addEventListener('offline', this.handleNetworkChange);
  },
  beforeDestroy() {
    window.removeEventListener('online', this.handleNetworkChange);
    window.removeEventListener('offline', this.handleNetworkChange);
  },
  methods: {
    handleNetworkChange(event) {
      const { type } = event;
      this.onLine = type === 'online';
    },
  },
};
</script>

<style lang="scss" scoped>
.glitch {
    animation: glitch-skew 4s cubic-bezier(.25, .46, .45, .94) 4s infinite;
    font-family: orbitron, sans-serif;
    font-size: 4rem;
    font-weight: 500;
    line-height: 1;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transform-origin: center top;

    &__main {
        color: #FFF;
    }

    &__color {
        height: 100%;
        left: 0;
        opacity: 0.8;
        position: absolute;
        top: 0;
        transform-origin: center center;
        width: 100%;
        z-index: -1;

        &--red {
            animation: glitch 300ms cubic-bezier(.25, .46, .45, .94) infinite;
            color: red;
        }

        &--blue {
            animation: glitch 300ms cubic-bezier(.25, .46, .45, .94) infinite reverse;
            color: blue;
        }

        &--green {
            animation: glitch 300ms cubic-bezier(.25, .46, .45, .94) 100ms infinite;
            color: #00FF0B;
        }
    }

    &__line {
        animation: glitch-line 2s linear infinite;
        background: #000;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;

        &--first {
            animation: glitch-line 2s linear infinite;
        }

        &--second {
            animation: glitch-line 1s linear 1s infinite;
        }
    }
}

@keyframes glitch {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-8px, 8px);
  }
  40% {
    transform: translate(-8px, -8px);
  }
  60% {
    transform: translate(8px, 8px);
  }
  80% {
    transform: translate(8px, -8px);
  }
  100% {
    transform: translate(0);
  }

}

@keyframes glitch-skew {
  0% {
    transform: skew(0deg, 0deg);
  }
  48% {
    transform: skew(0deg, 0deg);
    filter: blur(0);
  }
  50% {
    transform: skew(-20deg, 0deg);
    filter: blur(4px);
  }
  52% {
    transform: skew(20deg, 0deg);
  }
  54% {
    transform: skew(0deg, 0deg);
    filter: blur(0);
  }
  100% {
    transform: skew(0deg, 0deg);
  }

}

@keyframes glitch-line {
  0% {
    top: 0;
  }

  100% {
    top: 100%;
  }

}
</style>
