const clone = (object) => {
  return JSON.parse(JSON.stringify(object));
};

const empty = (object) => {
  return object && Object.keys(object).length === 0 && object.constructor === Object;
};

export {
  clone,
  empty
};
