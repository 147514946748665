import Vue from 'vue';
import VueGtag from 'vue-gtag';
import Notifications from 'vue-notification';

import App from '@/App';
import '@/registerServiceWorker';
import '@/utils/filters';
import vuetify from '@/plugins/vuetify';
import '@/plugins/config';
import '@/utils/array';
import router from '@/router';
import store from '@/store';

// Amplify
import { Amplify } from 'aws-amplify';
import amplifyConfig from './config';
Amplify.configure(amplifyConfig);
// Amplify

Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue();

Vue.use(store);

Vue.use(Notifications);

Vue.use(VueGtag, {
  config: {
    id: 'UA-96669265-4'
  }
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  vuetify,
  render: h => h(App)
});
