const state = {
  authenticated: true,
  user: {
    email: 'john@doe.com',
    marketingConsent: 'Sat Jun 09 2018 21:28:17 GMT+0200 (Central European Summer Time)',
    name: 'John Doe',
    phone: '+1555555555',
    userName: 'JohnFuckingDoe'
  },
  instances: [
    {
      identifier: 'safasdasdf',
      name: 'asfasfdaf',
      timezone: 'Africa/Zimbabwe',
      description: 'Whatever',
      template: 'DEFAULT'
    }
  ],
  releases: [],
  activeInstance: 'safasdasdf'
};

export default state;
