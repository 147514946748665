/* eslint-disable no-shadow */

function defaultState() {
  return {
    notification: {
      current: undefined,
      colors: {
        error: 'red',
        success: 'green',
        info: 'blue',
      },
      types: [
        'error',
        'success',
        'info',
      ],
    },
    pageTitle: '',
    loading: false,
    theme: 'light',
  };
}

const state = defaultState();

const getters = {
  notification: (state) => state.notification.current,
  colors: (state) => state.notification.colors,
  pageTitle: (state) => state.pageTitle,
  loading: state => state.loading,
  theme: state => state.theme
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  notify(state, payload) {
    state.notification.current = payload;
    const { timeout } = payload;

    if (timeout !== -1) {
      setTimeout(() => { this.commit('application/reset'); }, timeout);
    }
  },
  setPageTitle(state, pageTitle) {
    state.pageTitle = pageTitle;
  },
  loading (state, payload) {
    state.loading = payload;
  },
  switchTheme (state, theme) {
    if (theme) {
      state.theme = theme;
    } else {
      state.theme = state.theme === 'dark'
        ? 'light'
        : 'dark';
    }
  }
};

const actions = {
  reset({ commit }) {
    commit('reset');
  },
  notify({ commit }, payload) {
    commit('notify', payload);
  },
  setPageTitle({ commit }, pageTitle) {
    commit('setPageTitle', pageTitle);
  },
  globalStateReset() {
    this.reset();
  },
  loading ({ commit }, payload) {
    commit('loading', payload);
  },
  switchTheme({ commit }, payload) {
    commit('switchTheme', payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
