import Apps from '@/services/apps';

function defaultState() {
  return {
    apps: []
  };
}

const state = defaultState();

const getters = {
  apps: (state) => state.apps,
  getApp: (state) => (id) => state.apps.find(app => app.client_id === id)
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  add (state, payload) {
    state.apps.push(payload);
  },
  remove (state, app) {
    state.apps.remove(app);
  },
  apps (state, apps) {
    state.apps = apps;
  }
};

const actions = {
  async add({ commit }, payload) {
    const {
      instanceId,
      app: {
        name,
        provider,
        description,
        website,
        callback: web_server_redirect_uri
      }
    } = payload;
    const data = {
      data: {
        additional_information: {
          name,
          provider,
          description,
          website
        },
        web_server_redirect_uri
      }
    };

    try {
      const app = await Apps.create(instanceId, data);
      commit('add', app);
    } catch (error) {
      throw new Error(`Couldn't add the app: ${error.message}`);
    }
  },
  async update(_, payload) {
    const {
      instanceId,
      id,
      app: {
        additional_information,
        web_server_redirect_uri
      }
    } = payload;

    try {
      await Apps.update(instanceId, id, { data: { web_server_redirect_uri, additional_information } });
    } catch (error) {
      throw new Error(error);
    }

  },
  async destroy(_, payload) {
    const {identifier, id } = payload;

    try {
      await Apps.destroy(identifier, id);
    } catch (error) {
      throw new Error(`Couldn't delete the app: ${error.message}`);
    }
  },
  async fetch({ commit }, identifier) {
    try {
      const { data } = await Apps.list(identifier);
      const apps = data.filter(app => !app.additional_information.immutable);
      commit('apps', apps);
    } catch (error) {
      throw new Error(`Couldn't fetch the apps: ${error.message}`);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
