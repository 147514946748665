import Vue from 'vue';
import Vuetify from 'vuetify';
import themes from './themes';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

const opts = {
  iconfont: 'mdi',
  theme: {
    options: { cspNonce: 'dQw4w9WgXcQ' },
    dark: true,
    themes,
  },
};

export default new Vuetify(opts);
