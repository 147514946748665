// sign in & sign up
import Login from '@/views/Login';
import Register from '@/views/Registration/Index';
import Confirmation from '@/views/Registration/Confirmation';
import Confirm from '@/views/Registration/Confirm';
import PasswordReset from '@/components/Access/PasswordReset';
import PasswordResetConfirm from '@/components/Access/PasswordResetConfirm';
const routes = [
  {
    path: '/',
    component: Login,
    name: 'Login'
  },
  {
    path: '/register',
    component: Register,
    name: 'Register'
  },
  {
    path: '/confirmation',
    component: Confirmation,
    name: 'Confirmation'
  },
  {
    path: '/confirm',
    component: Confirm,
    name: 'Confirm'
  },
  {
    path: '/password_reset',
    component: PasswordReset,
    name: 'PasswordReset'
  },
  {
    path: '/password_reset_confirm',
    component: PasswordResetConfirm,
    name: 'PasswordResetConfirm'
  }
];

export default routes.map(route => {
  const meta = {
    public: true,
    onlyLoggedOut: true
  };

  return {...route, meta};
});
