import axios from 'axios';
import { Auth } from 'aws-amplify';
// import store from '@/store'
import {authenticated} from '@/utils/auth';
// import store from "@/store";
// activate once we moved all the microservices/services
// to one root URL
// const client = axios.create({
//   baseURL: process.env.API_URL // https://api.mbanq.com
// })

const request = async function (options) {

  if(await authenticated()){
    const { idToken: { jwtToken: authHeader } }= await Auth.currentSession();

    const onSuccess = function (response) {
      return response.data;
    };

    const onError = function (error) {
      return Promise.reject(error);
    };

    const client = axios.create({
      headers: { Authorization: authHeader }
    });

    const req = await client(options)
      .then(onSuccess)
      .catch(onError);

    return req;
  }else {
    window.location.href = '/';

    return Promise.reject('not authenticated');

  }

};

export default request;
