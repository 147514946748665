<template>
  <v-app-bar
    app
    clipped-left
    color="background"
  >
    <v-container fluid>
      <v-row
        justify="center"
        align="center"
      >
        <back-button />
        <v-toolbar-items>
          <h3 class="pageTitle">
            {{ pageTitle }}
          </h3>
        </v-toolbar-items>
        <v-spacer />
        <v-toolbar-items
          class="pr-1"
        >
          <idle-counter />
        </v-toolbar-items>
        <v-toolbar-items
          class="icon"
        >
          <router-link
            :to="{ name: 'Settings' }"
          >
            <v-icon>
              mdi-account-circle-outline
            </v-icon>
          </router-link>
        </v-toolbar-items>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'ApplicationBar',
  components: {
    BackButton: lazyLoad('components/shared/BackButton'),
    IdleCounter: lazyLoad('components/shared/IdleCounter')
  },
  computed: {
    ...mapGetters('application', [
      'pageTitle',
    ]),
  },
};
</script>

<style scoped>
.pageTitle {
  padding-left: 0.5em;
}
</style>
