<template>
  <v-navigation-drawer
    app
    clipped
    color="element"
  >
    <v-list
      dense
      nav
    >
      <v-list-item
        v-for="(item, index) in menu"
        :key="index"
        color="primary"
        :to="item.route"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import menu from '@/config/menu.js';

export default {
  name: 'SideMenu',
  data () {
    return {
      menu
    };
  }
};
</script>

<style lang="scss" scoped>
.md-app-drawer .active-route {
  font-weight: bold !important;
  background: #595959;
}
.md-list-item-text * {
  line-height: 3em;
}
</style>
