<template>
  <div
    id="account-reset"
  >
    <h1
      class="mb-5"
    >
      Reset Password
    </h1>

    <div
      v-if="error"
      class="alert alert-danger"
    >
      <p
        v-if="error"
        class="error"
      >
        {{ error.message }}
      </p>
    </div>

    <form @submit.prevent="resetPassword">
      <v-text-field
        id="email"
        v-model="email"
        type="text"
        autocapitalize="none"
        inputmode="email"
        autocomplete="email"
        required
        label="E-Mail Address"
        outlined
        hint="your username or email address"
      />

      <v-btn
        type="submit"
        color="primary"
        block
        x-large
        :loading="loading"
        :disabled="v$.$invalid"
      >
        Reset
      </v-btn>
    </form>

    <div class="mt-5 text-uppercase text-center">
      <router-link
        id="login"
        :to="{ name: 'Login' }"
      >
        Login
      </router-link>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { email } from '@/utils/validators';
import { mapActions } from 'vuex';

export default {
  setup () {
    return {
      v$: useVuelidate()
    };
  },
  data () {
    return {
      email: '',
      error: null,
      loading: false
    };
  },
  validations: {
    email
  },
  methods: {
    ...mapActions('auth', [
      'forgotPassword'
    ]),
    async resetPassword() {
      this.loading = true;

      try {
        this.forgotPassword(this.email);
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Error',
          message: error
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
