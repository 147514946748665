<template>
  <v-app>
    <v-fade-transition mode="out-in">
      <loading v-if="loading" />
      <private-layout v-else-if="authenticated" />
      <public-layout v-else />
    </v-fade-transition>
    <offline />
    <notifications />
    <spinner />
    <debugger ref="debugger" />
    <refresh-app />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import PublicLayout from '@/layouts/Public/Container';
import PrivateLayout from '@/layouts/Private/Container';
import Spinner from '@/components/shared/Spinner';
import Loading from '@/views/Loading';
import Offline from '@/components/shared/Offline';
import Debugger from '@/components/Debugger';
import RefreshApp from '@/components/shared/RefreshApp';

export default {
  components: {
    PublicLayout,
    PrivateLayout,
    Spinner,
    Loading,
    Offline,
    Debugger,
    RefreshApp
  },
  computed: {
    ...mapGetters('application', [
      'theme',
      'loading',
    ]),
    ...mapGetters('auth', [
      'authenticated',
    ])
  },
  created() {
    this.$config.prefersDarkMode
      ? this.$store.dispatch('application/switchTheme', 'dark')
      : this.$store.dispatch('application/switchTheme', 'light');
    document.addEventListener('keydown', (event) => {
      // 'ctrl + /' - DEBUGGER
      if (event.ctrlKey && event.keyCode === 191) {
        this.$refs.debugger.toggle();
      }
    });
  },
  mounted() {
    this.$vuetify.theme.dark = this.theme === 'dark';
  }
};
</script>

<style lang="scss">
/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none !important;
  width: 0px !important;
  background: transparent !important;
}

/* Hide scrollbar for IE, Edge add Firefox */
body {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important; /* Firefox */
}

.theme--light.v-application {
  background: #fff !important;
  color: rgba(0,0,0,.87) !important;
  background-image: radial-gradient(#e3e3e3 1px,transparent 0), radial-gradient(#e3e3e3 1px,transparent 0) !important;
  background-position: 0 0,25px 25px !important;
  background-attachment: fixed !important;
  background-size: 50px 50px !important;
}

#app {
  font-family: sans-serif !important;
}
.centered {
  text-align: center;
}
.uppercase {
 text-transform: uppercase;
}
.icon a {
  text-decoration: none !important;
}
.version {
  display: none;
  text-align: center;
}
</style>
